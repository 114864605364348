@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    font-family: 'Roboto', sans-serif;
}

html, body {margin: 0; height: 100%; overflow: hidden}

#card {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    font-family: 'Roboto', sans-serif;
    width: 20vw;
    padding: 2%;
    display: inline-block;
    border-radius: 0;
}

h1 {
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}

h6 {
    font-family: 'Roboto', sans-serif;
    font-weight:lighter;
    color: black;
}

.dashboard {
    text-align: left;
    line-height: 1;
}


@media screen and (max-width: 600px) {
    #card {
        position: fixed;
        width: 100vw;
        bottom: 0;
        margin: 30vh auto auto auto;
        padding-top: 10vh;
        padding-right: 15vw;
        padding-left: 15vw;
        border: none;
    }
    #big {
        height: 75vh;
    }
    /* body {
        overflow: auto;
        height: 100vh;
    } */
}